import {Link} from "gatsby";
import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import dbpic from '../images/dad/DBPortrait.jpg'
import dspic from '../images/dad/DSPortraitCropped.jpg'

let close = (onCloseArticle) => (
    <div
        className="close"
        onClick={() => {
            onCloseArticle()
        }}
    />)

const People = ({article, onCloseArticle, articleTimeout}) => {
    return <article
        id="people"
        className={`${article === 'people' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
        }`}
        style={{display: 'none'}}
    >
        <h2 className="major">People</h2>


        <h3 className="major">Damien Burger</h3>
        <div className="image main portrait">
            <img className="dad-logo" src={dbpic} alt=""/>
        </div>


        <p className=''>
            Damien Burger <br/>
            Business Lead <br/>
            MBA, BEngineering<br/>
        </p>
        <p>Damien Burger is the business lead of DaD digital. His role is to manage the financial and strategic part of the business, including negotiating new partnerships. Prior to this Damien was also a director of Business Planning HQ where he helped startups raise capital.</p>

        <p>Since 2012 he has been the Chair of PARED Victoria, a company that runs two schools in the SE suburbs of Melbourne.</p>

        <h3 className="major">Damien Sawyer</h3>
        <div className="image main portrait">
            <img className="dad-logo" src={dspic} alt=""/>
        </div>

        <p className=''>
            Damien Sawyer <br/>
            Technical Lead <br/>
        </p>

        <p>Damien Sawyer serves as technical lead of DaD Digital.</p>
        <p>He has extensive experience in software development and technical team leadership, acquired through working in a wide range of industries, including manufacturing, government, supply chain, finance, legal and general corporate.</p>


        <p>He is passionate about the ever-evolving role technology plays in society and highly values robust and flexible solutions with a low cost of ownership.</p>

        {close(onCloseArticle)}
    </article>
}
People.propTypes = {
    timeout: PropTypes.bool
}

export default People
