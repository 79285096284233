import {Link} from "gatsby";
import PropTypes from 'prop-types'
import React from 'react'
import ContactUs from "../components/contactForm"
import People from "../components/people"
import banner from '../images/dad/banner.png'
import tickets from '../images/dad/bg.jpg'
import fp from '../images/dad/fp1.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <div className="image main">
            <img className="dad-logo" src={banner} alt="" />
          </div>

          <p>We are a boutique software agency specialising in advanced business applications.</p>

          <p>Unlike other development companies who sell software to service providers, we partner with service providers to grow the business together.</p>

          <p>Our production philosophy is to spend more time building a solid base so we spend less time fixing bugs. The result is more robust, flexible and user friendly software.</p>

          <p>Our considerable and demonstrable expertise includes the following areas:</p>
          
          
            <ul>
              <li>Business plan preparation</li>
              <li>Advanced software architecture and Engineering</li>
              <li>Software team management, including of temporary workforces</li>
              <li>Custom software integrations</li>
              <li>International partnerships</li>
            </ul>
          

         {/*<p>Our technical areas of interest and expertise are broad. We have delivered projects with significant exposure to the following paradigms:</p>*/}
         {/* <ul>*/}
         {/*   <li>Cloud computing / hosting on Azure and AWS</li>*/}
         {/*   <li>Windows and Linux server hosting</li>*/}
         {/*   <li>Docker / Kubernetes</li>*/}
         {/*   <li>Advanced CQRS / Event Sourcing</li>*/}
         {/*   <li>Advanced Reporting</li>*/}
         {/*   <li>Internationalisation / Globalisation</li>*/}
         {/*   <li>15+ years of Web Development over many stacks, from legacy Webforms to modern React</li>*/}
         {/*   <li>Advanced Database Design with MSSQL, PostgreSQL et al</li>*/}
         {/*   <li>.Net, TypeScript et al</li>*/}
         {/*   <li>Phone Developement with Xamarin and Ionic</li>*/}
         {/*   <li>Advanced devops, including CI/CD</li>*/}
         {/* </ul>*/}
         {/* */}
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <h3 className="major">tickets.org.au</h3>
          <p>Since 2015, DaD Digital has worked with <Link target="_blank" to="https://ascentsolutions.com.au/">Ascent Solutions</Link> to develop and manage <Link target="_blank" to="https://tickets.org.au">tickets.org.au</Link>, a full service ticketing platform targeting not for profit organisations.</p>
          <p>DaD's involvement included software consultancy, architecture and development. </p>
          
          <div className="image main">
            <img className="dad-logo" src={tickets} alt="" />
          </div>

          <h3 className="major">Fertility Pinpoint</h3>
          <div className="image main">
            <img className="dad-logo" src={fp} alt="" />
          </div>
          <p>
          Fertility Pinpoint supports thousands of women across the globe with natural family planning via The Billings Method <sup>®</sup>.     
          </p>
          <p>DaD Digital worked in partnership with the International leadership team of the Billings organisation to bring the service into existance. DaD has managed the service since its launch in 2012.</p>
          
          {close}
        </article>
  
        <People article={this.props.article}  onCloseArticle={this.props.onCloseArticle} articleTimeout={this.props.articleTimeout} />
        <ContactUs article={this.props.article} onCloseArticle={this.props.onCloseArticle} articleTimeout={this.props.articleTimeout}/>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
