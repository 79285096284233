import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        {/* <p className="copyright">&copy; DaD Digtial 2011 - {new Date().getFullYear()}, html5up.net</p> */}
        {/*<p className="copyright" style={{marginTop:'500px'}}>Design: html5up.net</p>*/}
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
