import {set} from "fp-ts";
import {isRight} from "fp-ts/Either";
import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Environment, GetEnvironmentSettings, GetName, PostContactUs, TicketsAPI} from "../services/dadAPI";
import * as dadAPI from '../services/dadAPI'

let close = (onCloseArticle) => (
    <div
        className="close"
        onClick={() => {
            onCloseArticle()
        }}
    />
)

let resetForm = () => {
    console.log('resetting forn')
    document.getElementById("name").value = ''
    document.getElementById("message").value = ''
    document.getElementById("email").value = ''
}

let closeAndClear = (onCloseArticle, clear) => (
    <div
        className="close"
        onClick={() => {
            onCloseArticle()
            clear()
        }}
    />
)

const Post = (name, email, message, setErrors, setPostCompleted) => {

    let api = new TicketsAPI(GetEnvironmentSettings[Environment.production])
    let f = {name: name, email: email, message: message}
    let r = dadAPI.ValidateForm(f)
    if (r.length > 0) {
        setErrors(r)
        return
    }

    let payload = {name: name, email: email, message: message}
    PostContactUs(api, payload)().then(x => {
        if (isRight(x)) {
            console.log('either', x.right)
            resetForm()
            setErrors([])
            setPostCompleted(true)
            return []
        } else {
            setErrors(x.left.errors)
        }
    })
}

const ContactUs = ({article, onCloseArticle, articleTimeout}) => {
    const [errors, setErrors] = useState([])
    const [postCompleted, setPostCompleted] = useState(false)
    const [showErrors, setShowErrors] = useState(false)

    return <article
        id="contact"
        className={`${article === 'contact' ? 'active' : ''} ${
            articleTimeout ? 'timeout' : ''
        }`}
        style={{display: 'none'}}
    >

        {!!postCompleted && <>
            <div className='align-center'>Thank you for your message. If you've requested a reply we'll be in touch soon.</div>
        </>}
        {!postCompleted &&
        <>
            <h2 className="major">Contact</h2>

            <form method="post" action="#">
                <div className="field half first">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" defaultValue={''}/>
                </div>
                <div className="field half">
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" defaultValue={''}/>
                </div>
                <div className="field">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" rows="4" defaultValue={''}></textarea>
                </div>

                <div className='errorMessages'>
                    <ul>
                        {showErrors && errors.map(x => <li>{x}</li>)}
                    </ul>
                </div>

                <ul className="actions">
                    <li>
                        <input type="button" value="Send Message"
                               className="special"
                               onClick={() => {
                                   Post(document.getElementById("name").value, document.getElementById("email").value, document.getElementById("message").value, setErrors, setPostCompleted)
                                   setShowErrors(true)
                               }}/>
                    </li>
                    <li>
                        <input type="reset" value="Reset" onClick={() => setErrors([])}/>
                    </li>
                </ul>
            </form>
        </>
        }

        {closeAndClear(onCloseArticle, () => setPostCompleted(false))}
    </article>
}


ContactUs.propTypes = {
    timeout: PropTypes.bool
}

export default ContactUs
