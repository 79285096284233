import {isRight} from "fp-ts/Either";
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {Environment, TicketsAPI, GetName, GetEnvironmentSettings} from "../services/dadAPI";
import logo from "../images/dad/logo.png"
const Header = props => {

    // const [name,setName] = useState('')
    // let api = new TicketsAPI(GetEnvironmentSettings[Environment.local])
    // useEffect(()=>{
    //     GetName(api)().then(x=>{
    //         if (isRight(x)) {
    //             console.log('either', x.right)
    //             setName(x.right)
    //         }
    //     })
    // })
    


    
    return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/*<div className="logo">*/}
    {/*  <span className="icon fa-diamond"></span>*/}
    {/*</div>*/}
    
    <div className="dad-logo-bg">
      <img className="dad-icon" alt='logo' src={logo}/>      
    </div>
      
      
    <div className="content">
      <div className="inner">
        <h1>DaD Digital</h1>
        <p>
          Software Engineering Partnerships
        </p>
          <p>
              Melbourne, Australia
          </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>

          <li>
              <button
                  onClick={() => {
                      props.onOpenArticle('people')
                  }}
              >
                  People
              </button>
          </li>
          
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)}

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
