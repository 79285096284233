export const add = (i,j) => `hello from ts 1231231 ${i + j}`;
import {fromNullable, none} from "fp-ts/Option";
import axios, {AxiosRequestConfig, AxiosProxyConfig, CancelTokenSource} from "axios";
 import {isSome, Option, some} from "fp-ts/Option";
import * as O from "fp-ts/Option";
import {lastValueFrom} from "rxjs";
import * as TE from "fp-ts/TaskEither";
import {TaskEither} from "fp-ts/TaskEither";
import {pipe} from "fp-ts/function";



export enum Environment {
    production = "Production",
    development = "Development",
    local = "Localhost",
    localFiddler = "Local Fiddler"
}

export interface AxiosErrorWithStatusCode {
    /**The status code returned with the error. Eg 403 */
    status: number,
    statusText: string,
    message : string,
    data:{errors:string[]}
}

export const onRejected: (reason: unknown) => AxiosError = (r: any) =>{
    return !!r.response
    ? {status: r.response.status, statusText: r.response.statusText, message:r.message, errors:(r.response.data?.errors?.length ?? 0) > 0  ? r.response.data.errors : ['We are sorry, but something went wrong'] }
    : {code: r.code, message: r.message}
}

export type ContactUsFormModel = {
    name:string,
    email:string,
    message:string
}

export type ContactUsFormResultsModel = {
    errors:string[],
    valid:boolean
}


export type EnvironmentSettings = {
    environment: Environment,
    baseUrl: string,
    proxy: Option<AxiosProxyConfig>,
    selectedCompany:Option<string>,
    bearerToken: Option<string>,
    handleSelfSignedCerts: boolean, // This is needed to point to local. Note that, if you're going through fiddler, you're good.
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export const defaultEnvironment: EnvironmentSettings = {
    baseUrl: "",
    environment: Environment.local,
    selectedCompany:none,
    proxy: fromNullable(undefined),
    bearerToken:none,
    handleSelfSignedCerts: false
}

export const GetEnvironmentSettings: EnumDictionary<Environment, EnvironmentSettings> = {
    [Environment.development]: {...defaultEnvironment, environment: Environment.development, baseUrl: 'https://dev.tickets.org.au'},
    [Environment.production]: {...defaultEnvironment, environment: Environment.production, baseUrl: 'https://app.tickets.org.au'},
    [Environment.local]: {...defaultEnvironment, environment: Environment.local, baseUrl: 'https://welcomemat.com', handleSelfSignedCerts: false},
    [Environment.localFiddler]: {...defaultEnvironment, environment: Environment.localFiddler, baseUrl: 'https://welcomemat.com', proxy: fromNullable({port: 8888, host: 'localhost'})},
}

export class TicketsAPI {
    public axiosConfig: AxiosRequestConfig;
    public axiosCancellationSource: CancelTokenSource;

    constructor(public environmentSettings: EnvironmentSettings) {

        this.axiosCancellationSource = axios.CancelToken.source();

        this.axiosConfig = {
            //proxy:undefined,
            proxy: (isSome(environmentSettings.proxy) && pipe(environmentSettings.proxy, O.match(() => undefined, x => x))), // possibly don't need to pipe if && is short-circuiting
            // see https://github.com/axios/axios/issues/606#issuecomment-269648154
            headers: {
                ...(isSome(environmentSettings.bearerToken) && {Authorization: `Bearer ${environmentSettings.bearerToken.value}`}),
                // I SPENT AN AFTERNOON ON THIS. If you add a custom header here, then you have to add it in web.config under Access-Control-Allow-Headers (and possibly Access-Control-Expose-Headers)
                ...(isSome(environmentSettings.selectedCompany) && {CompanyId: `${environmentSettings.selectedCompany.value}`}),
            },
            //httpsAgent: true || environmentSettings.handleSelfSignedCerts ? new Agent({rejectUnauthorized: false}) : new Agent({rejectUnauthorized: false}),
            cancelToken: this.axiosCancellationSource.token

        };
        axios.defaults.adapter = require('axios/lib/adapters/http'); // search for "configure axios adapter" from wallaby support in gmail. https://github.com/axios/axios/issues/1754#issuecomment-572778305 
    }


    public TCExample = (httpCode: number) => TE.tryCatch(() => axios.get(`https://httpstat.us/${httpCode}`), onRejected)
}


export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/** Get these when you get the host wrong etc. */
export interface AxiosErrorWithNoStatusCode {
    code: number,
    message : string
}

export type AxiosError = AxiosErrorWithStatusCode | AxiosErrorWithNoStatusCode
export type ticketsQuery<TProps, TResult> = (e: TicketsAPI, p: TProps) => TaskEither<AxiosError, TResult>


export const GetName: ticketsQuery<string, string> = (e: TicketsAPI) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/dadapi/dadbrochuresite/getname`, e.axiosConfig).then(r => r.data), onRejected)

export const PostContactUs: ticketsQuery<ContactUsFormModel, ContactUsFormResultsModel[]> = (e: TicketsAPI, data) =>
    TE.tryCatch(() => axios.post(`${e.environmentSettings.baseUrl}/dadapi/dadbrochuresite/DaDContactUsForm`,data, e.axiosConfig).then(r =>  r.data), onRejected)


export const ValidationFunctions = ():{name:string, check:(c:ContactUsFormModel)=>string}[] => [
    {name:'Has Name', check:(c) => !!c.name ? '' : 'Please enter your name'},
    {name:'Has Email', check:(c) => !!c.email ? '' : 'Please enter your email address'},
    {name:'Has Message', check:(c) => !!c.message ? '' : 'Please enter a message'},
    {name:'Email Valid', check:(c) => !c.email || validateEmail(c.email) ? '' : 'Please enter a valid email address'},
    {name:'Not long message', check:(c) => !c.message || c.message.length < 100000 ? '' : 'Message size is too long. Please be more brief.'},
    
]

export const ValidateForm = (c: ContactUsFormModel):string[] => {
    let result:string[] = []
    ValidationFunctions().map(x=> {
        let l = x.check(c)
        if (!!l) result.push(l)
    })
    
 return result;
    
}
